import React, { Component } from 'react';
import { withRouter } from "react-router";
import { UserSessionService } from './../UserSessionService';

class Error extends Component {
    static displayName = Error.name;

    renderErrorMessage(message) {
        return <h4 className="py-5 text-center text-danger"><i className="bi bi-exclamation-circle pr-3"></i>{message}</h4>;
    }

    render() {
        var error = this.props.error;
        if (error && error.status) {
            switch (error.status) {
                case 401:
                    window.location.href = "/users/login?redirect=" + encodeURIComponent(this.props.location.pathname);
                    return null;
                case 403:
                    var user = UserSessionService.get();
                    return this.renderErrorMessage("Accès à la ressource interdit (Utilisateur: " + user?.login + ")");
                case 404:
                    return this.renderErrorMessage("Ressource introuvable");
                default:
                    return this.renderErrorMessage("Une erreur inattendue est survenue (" + error.status + ")");
            }
        } else if (error && error.message && error.message.length) {
            return this.renderErrorMessage(error.message);
        } else {
            return null;
        }
    }
}

export default withRouter(Error);